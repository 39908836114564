import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';
import { BreadcrumbService } from '../../shared/modules/breadcrumbs/breadcrumb.service';
import { setCurrentGame } from '../../store/actions/persist.actions';
import { selectCurrentGame } from '../../store/selectors/persist.selectors';
import { GlobalState } from '../../store/store';
import { Games } from '../constants/game.enum';

@Injectable()
export class DashboardGameSetGuard {
    constructor(private store: Store<GlobalState>) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
        breadcrumbService: BreadcrumbService
    ): boolean {
        const game = route.data.game
            ? route.data.game
            : route.params.game
            ? route.params.game
            : null;

        if (game && Games.includes(game)) {
            this.store
                .select(selectCurrentGame)
                .pipe(take(1))
                .subscribe(currentGame => {
                    if (currentGame !== game) {
                        this.store.dispatch(setCurrentGame({ payload: game }));
                        breadcrumbService.set('@game', game);
                    }
                });
        } else {
            this.store.dispatch(setCurrentGame({ payload: null }));
            console.warn('Could not set game, this may be a problem!');
        }
        return true;
    }
}

